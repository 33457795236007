import * as http from '../../../../vue_shared/http'

interface User {
  id: number
  email?: string
  name_with_email?: string // eslint-disable-line camelcase
  name_or_email?: string // eslint-disable-line camelcase
  undeliverable_email?: boolean // eslint-disable-line camelcase
}

type UserType = 'requesters' | 'pocs' | 'staff'

interface BaseOptions {
  search_text?: string // eslint-disable-line camelcase
  page_number?: number // eslint-disable-line camelcase
}

type OptionType = 'departments' | 'tags' | 'closure_responses'

interface FetchOptions extends BaseOptions {
  type: OptionType
}

interface FetchUserOptions extends BaseOptions {
  type: UserType
}

export function fetchUsersByType(options: FetchUserOptions): Promise<User[]> {
  const { type, ...params } = Object.assign(
    {
      search_text: '',
      page_number: 1
    },
    options
  )

  return http.get<User[]>(`/client/requests/${type}`, params)
}

export function fetchUserByType(id: number, type: UserType): Promise<User> {
  return http.get<User>(`/client/requests/${type}/${id}`)
}

interface Option {
  id: number
  name: string
}

export function fetchOptionsByType(options: FetchOptions): Promise<Option[]> {
  const { type, ...params } = options
  return http.get<Option[]>(`/client/requests/${type}/`, params)
}

export function fetchOptionByType(
  id: number,
  type: OptionType
): Promise<Option> {
  return http.get<Option>(`/client/requests/${type}/${id}`)
}
