import { createApp } from 'vue'
import { injectToasted } from '../src/utils/vue-toasted/inject_toasted.js'
import AlertDeactivatedPoc from '../src/pages/admin/components/alert_deactivated_poc.vue'

export const app = createApp({
  components: { AlertDeactivatedPoc },
  template: '<AlertDeactivatedPoc />'
})

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById(
    'update-poc-live-portal-mount'
  ) as HTMLElement

  if (!el) return

  injectToasted(app)

  app.mount(el)
})
