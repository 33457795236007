import * as qs from 'qs'

interface QueryParam {
  [key: string]: any
}

export const stringify = (param: QueryParam = {}): string => {
  return qs.stringify(param, { arrayFormat: 'brackets' })
}

export const parse = (paramString: string): QueryParam => {
  return qs.parse(paramString, { plainObjects: true, parseArrays: true })
}
